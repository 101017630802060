<template>
  <el-form
    :label-position="labelPosition"
    :label-width="labelWidthFilter"
    :model="model"
    :rules="rules"
    @submit.native.prevent="doFilter"
    class="filter"
    ref="form"
  >
    <el-row>
      

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.name.label" :prop="fields.name.name">
          <el-input v-model="model[fields.name.name]"/>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.management.label" :prop="fields.management.name">
          <el-input v-model="model[fields.management.name]"/>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.address.label" :prop="fields.address.name">
          <el-input v-model="model[fields.address.name]"/>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.contactPerson.label" :prop="fields.contactPerson.name">
          <el-input v-model="model[fields.contactPerson.name]"/>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.contactEmail.label" :prop="fields.contactEmail.name">
          <el-input v-model="model[fields.contactEmail.name]"/>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.phoneNumber.label" :prop="fields.phoneNumber.name">
          <el-input v-model="model[fields.phoneNumber.name]"/>
        </el-form-item>
      </el-col>

      <el-col :lg="12" :md="16" :sm="24">
        <el-form-item :label="fields.linkToWebsite.label" :prop="fields.linkToWebsite.name">
          <el-input v-model="model[fields.linkToWebsite.name]"/>
        </el-form-item>
      </el-col>
    </el-row>

    <div class="filter-buttons">
      <el-button :disabled="loading" @click="doFilter" icon="el-icon-fa-search" type="primary">
        <app-i18n code="common.search"></app-i18n>
      </el-button>

      <el-button :disabled="loading" @click="doResetFilter" icon="el-icon-fa-undo">
        <app-i18n code="common.reset"></app-i18n>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterSchema } from '@/shared/form/filter-schema';
import { FundingAgencyModel } from '@/modules/funding-agency/funding-agency-model';

const { fields } = FundingAgencyModel;

const filterSchema = new FilterSchema([
  fields.id,
  fields.name,
  fields.management,
  fields.address,
  fields.contactPerson,
  fields.contactEmail,
  fields.phoneNumber,
  fields.linkToWebsite,
]);

export default {
  name: 'app-funding-agency-list-filter',

  data() {
    return {
      rules: filterSchema.rules(),
      model: {},
    };
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthFilter: 'layout/labelWidthFilter',
      loading: 'fundingAgency/list/loading',
      filter: 'fundingAgency/list/filter',
    }),

    fields() {
      return fields;
    },
  },

  async mounted() {
    this.model = filterSchema.initialValues(
      this.filter,
      this.$route.query,
    );

    return this.doFilter();
  },

  methods: {
    ...mapActions({
      doReset: 'fundingAgency/list/doReset',
      doFetch: 'fundingAgency/list/doFetch',
    }),

    async doResetFilter() {
      this.model = filterSchema.initialValues();
      this.$refs.form.clearValidate();
      return this.doReset();
    },

    async doFilter() {
      try {
        await this.$refs.form.validate();
        this.$refs.form.clearValidate();
      } catch (error) {
        return;
      }

      const filter = filterSchema.cast(this.model);
      return this.doFetch({
        filter,
      });
    },
  },
};
</script>

<style>
</style>
