<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.fundingAgency.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.fundingAgency.list.title"></app-i18n>
      </h1>

      <app-funding-agency-list-toolbar></app-funding-agency-list-toolbar>
      <app-funding-agency-list-filter></app-funding-agency-list-filter>
      <app-funding-agency-list-table></app-funding-agency-list-table>
    </div>
  </div>
</template>

<script>
import FundingAgencyListFilter from '@/modules/funding-agency/components/funding-agency-list-filter.vue';
import FundingAgencyListTable from '@/modules/funding-agency/components/funding-agency-list-table.vue';
import FundingAgencyListToolbar from '@/modules/funding-agency/components/funding-agency-list-toolbar.vue';

export default {
  name: 'app-funding-agency-list-page',

  components: {
    [FundingAgencyListFilter.name]: FundingAgencyListFilter,
    [FundingAgencyListTable.name]: FundingAgencyListTable,
    [FundingAgencyListToolbar.name]: FundingAgencyListToolbar,
  },
};
</script>

<style>
</style>
